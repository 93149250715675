import React, { useEffect, useLayoutEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from "react-player";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
// import WebFileDownloader from "web-file-downloader";
import streamSaver from "streamsaver";
import { WritableStream } from "web-streams-polyfill/ponyfill";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { LinearProgress, Typography } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Box } from "@material-ui/core";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import BarChartIcon from "@material-ui/icons/BarChart";
import TheatersIcon from "@material-ui/icons/Theaters";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// My Files
import { DatabaseContext, UserContext } from "../../../../context";
import ToolbarWithLogo from "../../../ToolbarWithLogo";
import CommentSection from "../CommentSection";
import BackButton from "../../../BackButton";
import firebase from "../../../../firebase";
import NoPermissionsMessage from "../../../NoPermissionsMessage";
import FileEventsDialog from "../FileEventsDialog";
import EditFileDialog from "../EditFileDialog";
import FileUsageDialog from "../FileUsageDialog";
import {
    shouldFileBeVisible,
    doesFileHaveDuplicate,
    bunnyVideoLibraryID,
} from "../../../../utility";
import AnalysisSelectDialog from "./AnalysisSelectDialog";
import PlaylistSelectDialog from "./PlaylistSelectDialog";

const useStyles = makeStyles((theme) => ({
    videoPlayer: {
        marginTop: 15,
        [theme.breakpoints.down("sm")]: { marginTop: 0 },
    },
    columnPadding: {
        [theme.breakpoints.down("sm")]: { paddingLeft: 0 },
        paddingRight: 0,
    },
    pageContainer: {
        paddingBottom: 65,
    },
    videoContainer: {
        position: "relative",
        paddingTop: "56.25%",
        marginTop: 10,
    },
    commentsDisabled: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
    },
    menuButton: {
        width: 55,
        height: 55,
        [theme.breakpoints.down("sm")]: { marginRight: 15 },
    },
    menuButtonColored: {
        width: 55,
        height: 55,
        [theme.breakpoints.down("sm")]: { marginRight: 15 },
        color: "rgb(0, 149, 61)",
    },
    displayName: {
        flexGrow: 1,
        paddingTop: 10,
        fontSize: 24,
        [theme.breakpoints.down("sm")]: { paddingLeft: 15, fontSize: 19 },
        overflowWrap: "break-word",
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        textOverflow: "ellipsis",
    },
    dateText: {
        [theme.breakpoints.down("sm")]: { paddingLeft: 15 },
        color: "rgb(140, 140, 140)",
    },
    commentSection: {
        [theme.breakpoints.down("sm")]: { paddingLeft: 15 },
        marginRight: 0,
    },
    titleRow: {
        marginRight: 0,
    },
    uploadedByText: {
        color: "grey",
    },
}));

// let secondsViewed = 0;
// let usageKey = makeid(19);
let lastProgress = 0.0;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VideoViewPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {
        files,
        folders,
        comments,
        notifications,
        events,
        users,
        groups,
        timelineInfo,
        playlistInfo,
    } = useContext(DatabaseContext);

    const [folder, setFolder] = useState();
    const [file, setFile] = useState();
    const playerRef = React.useRef();

    const { currentUserData } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);

    // const [playing, setPlaying] = useState(false);

    const [userHasPermission, setUserHasPermission] = useState();

    const [userCanUseAnalysis, setUserCanUseAnalysis] = useState(false);
    const [userCanUsePlaylists, setUserCanUsePlaylists] = useState(false);

    const [userIsManager, setUserIsManager] = useState(false);

    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
        useState(false);

    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [usageDialogOpen, setUsageDialogOpen] = useState(false);

    const [analysisSelectDialogOpen, setAnalysisSelectDialogOpen] =
        useState(false);
    const [playlistSelectDialogOpen, setPlaylistSelectDialogOpen] =
        useState(false);

    // const [usageKey, setUsageKey] = useState(makeid(19)); // The entry in the database used for recording file usage
    // const [secondsViewed, setSecondsViewed] = useState(0);

    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");

    const [uploadedBy, setUploadedBy] = useState("");

    const [hasLoggedUsage, setHasLoggedUsage] = useState(false);

    const [isInFavourites, setIsInFavourites] = useState(false);

    let downloadProgress = 0;

    // const action = (key) => {
    //     return (
    //         <Box position="relative" display="inline-flex">
    //             <CircularProgress color="primary" />
    //         </Box>
    //     );
    // };

    const action = (key) => {
        return (
            <Box position="relative" display="inline-flex">
                <Typography
                    variant="caption"
                    component="div"
                    color="inherit"
                >{`${Math.round(
                    downloadProgress / (1024 * 1024)
                )} MB`}</Typography>
            </Box>
        );
    };

    // const action = (key) => {
    //     return (
    //         <Box position="relative" display="inline-flex">
    //             <CircularProgress
    //                 variant="determinate"
    //                 value={downloadProgress}
    //             />
    //             <Box
    //                 top={0}
    //                 left={0}
    //                 bottom={0}
    //                 right={0}
    //                 position="absolute"
    //                 display="flex"
    //                 alignItems="center"
    //                 justifyContent="center"
    //             >
    //                 <Typography
    //                     variant="caption"
    //                     component="div"
    //                     color="inherit"
    //                 >{`${Math.round(downloadProgress)}%`}</Typography>
    //             </Box>
    //         </Box>
    //     );
    // };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            // detaching the listener
            if (!user) {
                // If no user is logged in, redirect to sign in page
                // history.replace("/signin");
                history.replace("/signin?url=" + location.pathname);
            }
        });

        // usageKey = makeid(19);
        // secondsViewed = 0;
        lastProgress = 0;

        if (currentUserData) {
            if (currentUserData.permissions.createOwnPlaylist === true) {
                setUserCanUsePlaylists(true);
            } else {
                setUserCanUsePlaylists(false);
            }

            if (currentUserData.permissions.analysis === true) {
                setUserCanUseAnalysis(true);
            } else {
                setUserCanUseAnalysis(false);
            }

            let fileKey = "";

            if (props.fileKey) {
                // If props.fileKey is set, then this page is being displayed inside a dialog
                // this happens when using the Files section of the site
                fileKey = props.fileKey;
            } else {
                // Otherwise the page is display as a standalone page
                // this happens when the page is accessed through a URL (e.g. by clicking on a notification)
                fileKey = location.pathname.replace("/video/", "");
            }

            // let currentFileKey = location.pathname.replace("/video/", "");

            let file = files.filter((f) => f.key === fileKey)[0];

            if (file) {
                setFile(file);

                let folder = folders.filter((f) => f.key === file.folder)[0];

                if (folder) {
                    setFolder(folder);
                }

                if (shouldFileBeVisible(file, groups, currentUserData)) {
                    setUserHasPermission(true);
                } else {
                    setUserHasPermission(false);
                }

                if (currentUserData.faveFiles) {
                    if (currentUserData.faveFiles.includes(file.key)) {
                        setIsInFavourites(true);
                    } else {
                        setIsInFavourites(false);
                    }
                } else {
                    setIsInFavourites(false);
                }

                setUserIsManager(
                    file.managers.includes(currentUserData.key) ||
                        currentUserData.role === "System Digital Manager" ||
                        file.uploadedBy === currentUserData.key
                );

                if (file.uploadedBy) {
                    let uploadedByUser = users.filter(
                        (u) => u.key === file.uploadedBy
                    )[0];
                    if (uploadedByUser) {
                        setUploadedBy(
                            uploadedByUser.firstName +
                                " " +
                                uploadedByUser.lastName
                        );
                    }
                }

                // Record File Access
                if (!hasLoggedUsage) {
                    console.log("Log File Usage");

                    let ref = firebase.database().ref("fileusage");

                    ref.push({
                        file: file.key,
                        fileName: file.displayName,
                        fileType: file.type,
                        user: currentUserData.key,
                        action: "Open",
                        date: dayjs().toString(),
                    });

                    setHasLoggedUsage(true);
                }
            }
        }

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [files, file, location.pathname, history, currentUserData]);

    // This is called when leaving the page using the back button or changing the url
    useLayoutEffect(() => {
        return () => {
            // let fileUsageRef = firebase.database().ref("fileusage");
            // fileUsageRef
            //     .child(usageKey)
            //     .update({ secondsViewed: Math.round(secondsViewed) });
        };
    }, []);

    // This is called if the browser tab or window is closed
    window.onbeforeunload = () => {
        // let fileUsageRef = firebase.database().ref("fileusage");
        // fileUsageRef
        //     .child(usageKey)
        //     .update({ secondsViewed: Math.round(secondsViewed) });
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.target);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function handleDeleteClick() {
        setAnchorEl(null);
        setConfirmDeleteDialogIsOpen(true);
    }

    function handleConfirmDeleteDialogClose() {
        setConfirmDeleteDialogIsOpen(false);
    }

    function handleEventDialogOpen() {
        setEventDialogOpen(true);
    }

    function handleCloseEventDialog() {
        setEventDialogOpen(false);
    }

    function handleEditClick() {
        setAnchorEl(null);
        setEditDialogOpen(true);
    }

    function handleUsageClick() {
        setAnchorEl(null);
        setUsageDialogOpen(true);
    }

    async function handleConfirmDeletePressed() {
        if (userIsManager) {
            setDeleteInProgress(true);

            // console.clear();
            // console.log("Timelines: " + timelines.length);
            // console.log("Playlists: " + playlists.length);
            if (doesFileHaveDuplicate(file, files)) {
                deleteFromFirebase();
            } else {
                await deleteFromBunnyStorage().then(() => {
                    deleteFromFirebase();
                });
            }
        }

        setConfirmDeleteDialogIsOpen(false);
    }

    function deleteFromFirebase() {
        // Delete the comments
        comments.forEach((comment) => {
            if (comment.file === file.key) {
                let commentRef = firebase
                    .database()
                    .ref("comments")
                    .child(comment.key);
                commentRef.update({ deleted: true });
            }
        });
        // Delete any notifications for the file
        notifications.forEach((notification) => {
            if (notification.file === file.key) {
                let notificationRef = firebase
                    .database()
                    .ref("notifications")
                    .child(notification.key);
                notificationRef.remove();
            }
        });
        // Delete the file in any events
        events.forEach((event) => {
            let filesToKeepArray = [];
            let needsChange = false;
            event.files.forEach((aFile) => {
                if (aFile.key === file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile.key);
                }
            });
            if (needsChange) {
                let eventRef = firebase
                    .database()
                    .ref("events")
                    .child(event.key);
                // Change remaining files to JSON
                let filesObject = {};
                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });
                eventRef.update({ files: filesObject });
            }
        });

        // Delete playlists that use the video
        playlistInfo.forEach((playlist) => {
            if (playlist.video === file.key) {
                let playlistsRef = firebase
                    .database()
                    .ref("playlists")
                    .child(playlist.key);
                playlistsRef.remove();

                let playlistInfoRef = firebase
                    .database()
                    .ref("playlistinfo")
                    .child(playlist.key);

                playlistInfoRef.remove();
            }
        });

        // Delete timelines that use the video
        timelineInfo.forEach((timeline) => {
            if (timeline.video === file.key) {
                let timelinesRef = firebase
                    .database()
                    .ref("timelines")
                    .child(timeline.key);
                timelinesRef.remove();

                let timelineInfoRef = firebase
                    .database()
                    .ref("timelineinfo")
                    .child(timeline.key);

                timelineInfoRef.remove();
            }
        });

        // Delete the file from any users (they are added here if the user is added manually to a file permissions when uploading)
        users.forEach((user) => {
            let filesToKeepArray = [];
            let needsChange = false;
            user.files.forEach((aFile) => {
                if (aFile === file.key) {
                    needsChange = true;
                } else {
                    filesToKeepArray.push(aFile);
                }
            });
            if (needsChange) {
                let userRef = firebase.database().ref("users").child(user.key);
                // Change remaining files to JSON
                let filesObject = {};
                filesToKeepArray.forEach((aFile) => {
                    filesObject[aFile] = true;
                });
                userRef.update({ files: filesObject });
            }
        });

        // Delete the file
        let fileRef = firebase.database().ref("files").child(file.key);
        fileRef.remove();
        setDeleteInProgress(false);

        if (props.fileKey === undefined) {
            history.goBack();
        } else {
            props.onClose();
        }
    }

    function deleteFromBunnyStorage() {
        return new Promise((resolve, reject) => {
            var getBunnyStreamAPIKey = firebase
                .functions()
                .httpsCallable("getBunnyStreamAPIKey");

            getBunnyStreamAPIKey()
                .then((result) => {
                    const apiKey = result.data.key;
                    let fileKey = file.key;

                    if (file.original) {
                        fileKey = file.original;
                    }

                    const url =
                        "https://video.bunnycdn.com/library/" +
                        bunnyVideoLibraryID +
                        "/videos/" +
                        fileKey;
                    const options = {
                        method: "DELETE",
                        headers: { AccessKey: apiKey },
                    };

                    fetch(url, options)
                        .then((response) => response.json())
                        .then((response) => {
                            console.log(response);
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            reject();
                        });
                })
                .catch((error) => {
                    // Getting the Error details.
                    console.log(error.code);
                    console.log(error.message);
                    console.log(error.details);
                    reject();
                });
        });
    }

    // function handleButtonPress() {
    //     setPlaybackRate(0.5);
    // }

    // function handlePlay() {
    //     setPlaying(true);

    //     console.log("Playing");
    // }

    // FEB

    async function handleDownloadClick() {
        if (file) {
            // This downloads the original file
            let fileKey = file.key;

            if (file.original) {
                fileKey = file.original;
            }

            let url =
                "https://vz-db0e7359-d70.b-cdn.net/" + fileKey + "/original";

            // console.log(url);

            // Streamsaver Method

            // let blob = await fetch(url).then((r) => r.blob());

            const fileStream = streamSaver.createWriteStream(file.filename, {});

            handleMenuClose();

            enqueueSnackbar("Downloading: " + file.filename, {
                variant: "default",
                action,
                persist: true,
                key: file.key,
                preventDuplicate: true,
            });

            downloadProgress = 0;

            const options = { method: "GET", headers: { accept: "*/*" } };

            fetch(url, options).then((res) => {
                console.log(res);
                const readableStream = res.body;

                // more optimized pipe version
                // (Safari may have pipeTo but it's useless without the WritableStream)

                if (!window.WritableStream) {
                    console.log("Set writable stream");
                    streamSaver.WritableStream = WritableStream;
                    window.WritableStream = WritableStream;
                }

                // if (window.WritableStream && readableStream.pipeTo) {
                //     console.log("IF");
                //     return readableStream.pipeTo(fileStream).then(() => {
                //         closeSnackbar(file.key);
                //         console.log("done writing");
                //     });
                // } else {
                //     console.log("ELSE");
                // }

                // Write (pipe) manually
                window.writer = fileStream.getWriter();
                // const writer = fileStream.getWriter();

                const reader = readableStream.getReader();
                const pump = () =>
                    reader.read().then((res) => {
                        if (res.done) {
                            console.log("CLOSE");
                            closeSnackbar(file.key);
                            // window.writer.abort();
                            window.writer.close();
                        } else {
                            // console.log(res);
                            downloadProgress += res.value.length;
                            enqueueSnackbar("Downloading: " + file.filename, {
                                variant: "default",
                                action,
                                persist: true,
                                key: file.key,
                                preventDuplicate: true,
                            });

                            window.writer.write(res.value).then(pump);
                        }
                    });

                pump();
            });

            // web-file-downloader Method
            // console.clear();
            // console.log("Here");
            // console.log(url);

            // const option = {
            //     maxDownloadConnect: 5,
            //     fileChunkSize: 5 * 1024 * 1024,
            // };

            // const downloader = new WebFileDownloader(option);

            // enqueueSnackbar("Downloading: " + file.filename, {
            //     variant: "default",
            //     action,
            //     persist: true,
            //     key: file.key,
            // });

            // const fileInfo = downloader.download(url, file.filename);

            // downloader.on("progress", (info) => {
            //     downloadProgress = info.progress;
            // enqueueSnackbar("Downloading: " + file.filename, {
            //     variant: "default",
            //     action,
            //     persist: true,
            //     key: file.key,
            //     preventDuplicate: true,
            // });

            //     console.log(info);

            //     if (info.status === "END") {
            //         // Record that the user downloaded the file

            //         console.log("FINISHED");
            //         let ref = firebase.database().ref("fileusage");

            //         ref.push({
            //             file: file.key,
            //             fileName: file.displayName,
            //             fileType: file.type,
            //             user: currentUserData.key,
            //             action: "Download",
            //             date: dayjs().toString(),
            //         });

            //         downloader.delete(info.fileId);
            //         downloadProgress = 0;
            //         closeSnackbar(fileKey);
            //     }
            // });

            // console.log("ID:");
            // console.log(fileInfo.fileId);
            // handleMenuClose();

            // OLD METHOD

            // This can download a 540p or 720p version
            // Only works on files that were uploaded after MP4 fallback was enabled on Bunny.net
            // let url =
            //     "https://vz-db0e7359-d70.b-cdn.net/" +
            //     location.file.key +
            //     "/play_720p.mp4"; // Can only download 540p or 720p using this method, needs vid

            // enqueueSnackbar("Downloading: " + file.filename, {
            //     variant: "default",
            //     action,
            //     persist: true,
            //     key: file.key,
            // });

            // // range: "bytes=0-1023",

            // axios({
            //     url: url,
            //     method: "GET",
            //     responseType: "blob",
            //     onDownloadProgress: (progressEvent) => {
            //         let percentCompleted = Math.floor(
            //             (progressEvent.loaded / progressEvent.total) * 100
            //         );

            //         downloadProgress = percentCompleted;

            // enqueueSnackbar("Downloading: " + file.filename, {
            //     variant: "default",
            //     action,
            //     persist: true,
            //     key: file.key,
            //     preventDuplicate: true,
            // });
            //     },
            // }).then((response) => {
            // FileDownload(response.data, file.filename);

            // // Record that the user downloaded the file

            // let ref = firebase.database().ref("fileusage");

            // ref.push({
            //     file: file.key,
            //     fileName: file.displayName,
            //     fileType: file.type,
            //     user: currentUserData.key,
            //     action: "Download",
            //     date: dayjs().toString(),
            // });

            // //

            // downloadProgress = 0;
            // closeSnackbar(file.key);
            // });
            // handleMenuClose();
        }
    }

    // function handleCopyDownloadLink() {
    //     if (file) {
    //         // This downloads the original file
    //         let fileKey = file.key;

    //         if (file.original) {
    //             fileKey = file.original;
    //         }

    //         let url =
    //             "https://vz-db0e7359-d70.b-cdn.net/" + fileKey + "/original";

    //         navigator.clipboard.writeText(url);

    //         showStatusMessage("Download Link Copied to Clipboard", "success");
    //         handleMenuClose();
    //     }
    // }

    function onProgress(progress) {
        let seconds = progress.playedSeconds;

        let diff = Math.abs(lastProgress - seconds);

        if (diff < 2.1) {
            // Ignore when difference is more than 2 seconds, because the user is probably skipping around the video
            // secondsViewed += diff;
        }

        lastProgress = seconds;
    }

    function handleCloseEditDialog(outcome) {
        if (outcome === "saved") {
            showStatusMessage("File edited successfully", "success");
        }

        setEditDialogOpen(false);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function handleCloseUsageDialog() {
        setUsageDialogOpen(false);
    }

    function showStatusMessage(message, type) {
        setStatusType(type);
        setStatusMessage(message);
        setStatusIsVisible(true);
    }

    async function handleAnalysisButtonPress() {
        setAnalysisSelectDialogOpen(true);
    }

    async function handlePlaylistButtonPress() {
        setPlaylistSelectDialogOpen(true);
    }

    function handleCloseAnalysisSelectDialog() {
        setAnalysisSelectDialogOpen(false);
    }

    function handleClosePlaylistSelectDialog() {
        setPlaylistSelectDialogOpen(false);
    }

    function handleAddToFavouritesPress() {
        // Get the users current favourite files
        let favesObject = {};

        currentUserData.faveFiles.forEach((faveFile) => {
            favesObject[faveFile] = true;
        });

        // Add the current file

        favesObject[file.key] = true;

        // Update firebase

        let ref = firebase.database().ref("users").child(currentUserData.key);
        ref.update({ faveFiles: favesObject });
    }

    function handleRemoveFromFavouritesPress() {
        // Get the users current favourite files
        let favesObject = {};

        currentUserData.faveFiles.forEach((faveFile) => {
            if (faveFile !== file.key) {
                favesObject[faveFile] = true;
            }
        });

        // Update firebase

        let ref = firebase.database().ref("users").child(currentUserData.key);
        ref.update({ faveFiles: favesObject });
    }

    const menuButtonClassName = (buttonTitle) => {
        if (buttonTitle === "Analysis") {
            return classes.menuButton;
        } else if (buttonTitle === "Playlists") {
            return classes.menuButton;
        }
        return classes.menuButton;
    };

    return (
        <>
            {userHasPermission && (
                <>
                    {props.fileKey === undefined && <BackButton />}
                    <Container fluid className={classes.pageContainer}>
                        <Row>
                            {/* <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<CloudDownloadIcon />}
                        onClick={handleDownloadClick}
                    >
                        Download
                    </Button> */}
                        </Row>
                        <Row>
                            <Col
                                className={classes.columnPadding}
                                lg={7}
                                md={12}
                                sm={12}
                            >
                                {file && (
                                    <>
                                        {/* <ReactHlsPlayer
                                    className={classes.videoPlayer}
                                    playerRef={playerRef}
                                    src={
                                        "https://vz-db0e7359-d70.b-cdn.net/" +
                                        file.key +
                                        "/playlist.m3u8"
                                    }
                                    autoPlay={false}
                                    controls={true}
                                    width="100%"
                                    hlsConfig={{
                                        startPosition: -1,
                                    }}
                                    height="auto"
                                /> */}
                                        <ReactPlayer
                                            className={classes.videoPlayer}
                                            ref={playerRef}
                                            url={
                                                file.original
                                                    ? "https://vz-db0e7359-d70.b-cdn.net/" +
                                                      file.original +
                                                      "/playlist.m3u8"
                                                    : "https://vz-db0e7359-d70.b-cdn.net/" +
                                                      file.key +
                                                      "/playlist.m3u8"
                                            }
                                            autoPlay={false}
                                            controls={true}
                                            onProgress={onProgress}
                                            // playing={playing}
                                            // onPlay={handlePlay}
                                            // playbackRate={playbackRate}
                                            width="100%"
                                            // hlsConfig={{
                                            //     startPosition: -1,
                                            // }}
                                            height="auto"
                                        />

                                        {/* <Button onClick={handleButtonPress}>
                                    Test
                                </Button> */}
                                        <Row className={classes.titleRow}>
                                            <Col>
                                                <Typography
                                                    className={
                                                        classes.displayName
                                                    }
                                                >
                                                    {file.displayName}
                                                </Typography>
                                                {uploadedBy !== "" && (
                                                    <span
                                                        className={
                                                            classes.uploadedByText
                                                        }
                                                    >
                                                        Uploaded By:
                                                        {uploadedBy}
                                                    </span>
                                                )}
                                            </Col>
                                            <Tooltip title="Events">
                                                <IconButton
                                                    onClick={
                                                        handleEventDialogOpen
                                                    }
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    aria-label="moreVideoEditOptions"
                                                >
                                                    <EventNoteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {userCanUseAnalysis && (
                                                <Tooltip title="Analysis">
                                                    <IconButton
                                                        onClick={
                                                            handleAnalysisButtonPress
                                                        }
                                                        className={menuButtonClassName(
                                                            "Analysis"
                                                        )}
                                                        aria-label="analysis"
                                                    >
                                                        <BarChartIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {userCanUsePlaylists && (
                                                <Tooltip title="Playlists">
                                                    <IconButton
                                                        onClick={
                                                            handlePlaylistButtonPress
                                                        }
                                                        className={menuButtonClassName(
                                                            "Playlists"
                                                        )}
                                                        aria-label="playlists"
                                                    >
                                                        <TheatersIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {isInFavourites ? (
                                                <Tooltip title="Remove From Favourites">
                                                    <IconButton
                                                        onClick={
                                                            handleRemoveFromFavouritesPress
                                                        }
                                                        className={
                                                            classes.menuButton
                                                        }
                                                        aria-label="moreVideoEditOptions"
                                                    >
                                                        <StarIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Add to Favourites">
                                                    <IconButton
                                                        onClick={
                                                            handleAddToFavouritesPress
                                                        }
                                                        className={
                                                            classes.menuButton
                                                        }
                                                        aria-label="moreVideoEditOptions"
                                                    >
                                                        <StarBorderIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <Tooltip title="More">
                                                <IconButton
                                                    onClick={handleMenuOpen}
                                                    className={
                                                        classes.menuButton
                                                    }
                                                    aria-label="moreVideoEditOptions"
                                                >
                                                    <MoreHorizIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Row>
                                        <Divider />
                                        <Row className={classes.commentSection}>
                                            {file.allowComments ? (
                                                <CommentSection
                                                    file={file}
                                                    videoPlayer={playerRef}
                                                />
                                            ) : (
                                                <span
                                                    className={
                                                        classes.commentsDisabled
                                                    }
                                                >
                                                    Comments are disabled for
                                                    this file
                                                </span>
                                            )}
                                        </Row>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {userIsManager && (
                            <MenuItem onClick={handleEditClick}>
                                Edit File
                            </MenuItem>
                        )}
                        {userIsManager && (
                            <MenuItem onClick={handleDeleteClick}>
                                Delete File
                            </MenuItem>
                        )}
                        {userIsManager && (
                            <MenuItem onClick={handleUsageClick}>
                                File Usage Data
                            </MenuItem>
                        )}
                        <MenuItem
                            disabled={!file.allowDownloads}
                            onClick={handleDownloadClick}
                        >
                            Download File
                        </MenuItem>
                        {/* <MenuItem
                            disabled={!file.allowDownloads}
                            onClick={handleCopyDownloadLink}
                        >
                            Copy Download Link
                        </MenuItem> */}
                    </Menu>
                </>
            )}
            {confirmDeleteDialogIsOpen && (
                <Dialog
                    open={confirmDeleteDialogIsOpen}
                    onClose={handleConfirmDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Delete File
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this file?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleConfirmDeleteDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeletePressed}
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                    {deleteInProgress && <LinearProgress />}
                </Dialog>
            )}
            {userHasPermission === false && <NoPermissionsMessage />}
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            <ToolbarWithLogo />
            {eventDialogOpen && (
                <FileEventsDialog
                    open={eventDialogOpen}
                    onClose={handleCloseEventDialog}
                    file={file}
                />
            )}
            {editDialogOpen && (
                <EditFileDialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    file={file}
                    folder={folder}
                />
            )}
            {analysisSelectDialogOpen && (
                <AnalysisSelectDialog
                    open={analysisSelectDialogOpen}
                    onClose={handleCloseAnalysisSelectDialog}
                    file={file}
                />
            )}
            {playlistSelectDialogOpen && (
                <PlaylistSelectDialog
                    open={playlistSelectDialogOpen}
                    onClose={handleClosePlaylistSelectDialog}
                    file={file}
                />
            )}
            {usageDialogOpen && (
                <FileUsageDialog
                    open={usageDialogOpen}
                    onClose={handleCloseUsageDialog}
                    file={file}
                    isVideo
                />
            )}
        </>
    );
}
